<template>
    <div class="building">
        <img src="../assets/images/common/building.jpg" alt="">
    </div>
</template>

<script>
    export default {};
</script>

<style lang="scss" scoped>
    .building {
        width: 62.5vw;
        margin: 0 auto;
        padding: 5.2083vw 0 0 0;
        min-height: 36.4583vw;
        img {
            display: block;
            width: 100%;
            border: none;
        }
    }
    @media (min-width: 1920px) {
        .building {
            width: 1200px;
            margin: 0 auto;
            padding: 100px 0 0 0;
            min-height: 700px;

            img {
                display: block;
                width: 100%;
                border: none;
            }
        }
    }
</style>